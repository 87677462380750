@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-weight: 500;
  font-size: 2.2rem;
}

h2 {
  font-weight: 500;
  font-size: 1.7rem;
}

h3 {
  font-weight: 500;
  opacity: 0.7;
}

a {
  color: #ff77ff;
  text-decoration: none;
}

em {
  font-weight: 600;
}

input {
  outline: none;
  width: 100%;
  margin: 10px 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 1.3rem;
  font-weight: 400;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 4px;
}

.gradient-background, .checkbox-active {
  background: linear-gradient(-45deg, #ffafbd20, #ffc3a020, #cc2b5e20, #753a8820, #2193b020, #6dd5ed20);
  background-size: 400% 400%;
  -webkit-animation: gradient 10s ease-in-out infinite;
          animation: gradient 10s ease-in-out infinite;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  transition: -webkit-filter 2s;
  transition: filter 2s;
  transition: filter 2s, -webkit-filter 2s;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-background:hover, .checkbox-active:hover {
  -webkit-filter: hue-rotate(360deg);
          filter: hue-rotate(360deg);
}

.app {
  width: 100%;
  overflow: hidden;
}

.app .page-wrapper {
  width: 100%;
  margin-top: 100px;
}

.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
}
/*# sourceMappingURL=App.css.map */
:root {
  --header-height: 100px;
  --color-cursor: 100, 100, 100;
  --cursor-outline-shade: 0.3;
  --cursor-size: 10px;
  --cursor-outline-size: 12px;
  --page-margin: 40px;
}
/*# sourceMappingURL=variables.css.map */


.home {
  width: 100vw;
  overflow: hidden;
}

.home .subtitle {
  margin-top: 80px;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}
/*# sourceMappingURL=index.css.map */
.lander {
  margin: 0 var(--page-margin);
}

.lander .cta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  margin: 20px 0px 60px 0px;
}

.lander .cta h1 {
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
}

.lander .cta .download {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lander .cta .download .button {
  margin-right: 20px;
}

.lander .cta .download .osLogos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  opacity: 0.7;
}

.lander .cta .download .osLogos img {
  cursor: pointer;
  height: 100%;
  padding: 3px;
}

.lander .cta .download .osLogos .android {
  height: 80%;
  margin-right: 12px;
}

.lander .hero img {
  width: 100%;
}

@media screen and (min-width: 961px) {
  .lander {
    height: calc(100vh - var(--header-height));
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .lander .cta {
    margin: 0;
    margin-top: calc(var(--header-height) * -0.5);
    max-width: 400px;
    margin-right: 40px;
    align-items: flex-start;
  }
  .lander .cta h1 {
    text-align: left;
  }
  .lander .hero {
    height: 90%;
  }
  .lander .hero img {
    height: 100%;
    width: auto;
  }
}
/*# sourceMappingURL=index.css.map */
.button {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 0px 10px 10px;
  cursor: pointer;
  text-align: center;
  border: 2px solid black;
}
/*# sourceMappingURL=index.css.map */
.product {
  margin: var(--page-margin);
  margin-bottom: 120px;
}

.product img {
  width: 100%;
  border-radius: 15px;
  border: 2px solid black;
  margin-bottom: 15px;
}

@media screen and (min-width: 961px) {
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product h2 {
    max-width: 400px;
  }
  .product .spacer {
    width: 50px;
  }
  .product img {
    max-width: 350px;
  }
}
/*# sourceMappingURL=Product.css.map */
.home {
  width: 100vw;
  overflow: hidden;
}

.home .textGraphicWrapper {
  margin: 40px;
  margin-bottom: 100px;
}

.home .textGraphicWrapper .textGraphic {
  margin-bottom: 120px;
}

.home .textGraphicWrapper .textGraphic:last-child {
  margin-bottom: 0px;
}
/*# sourceMappingURL=index.css.map */
.cookies .cookiesInner {
  margin: 40px;
  line-height: 25px;
}

.cookies .lastUpdate {
  opacity: 0.6;
  font-size: 0.9rem;
  font-style: italic;
}

.cookies p {
  margin-top: 13px;
}

.cookies h2 {
  font-weight: 600;
}

.cookies h3 {
  opacity: 1;
  margin-top: 27px;
  font-weight: 600;
}

.cookies h4 {
  margin-top: 27px;
  font-weight: 600;
}

.cookies ul {
  padding-left: 27px;
  list-style-type: circle;
  margin-top: 5px;
}

.cookies strong {
  font-weight: 600;
}
/*# sourceMappingURL=index.css.map */
.tos .tosInner {
  margin: 40px;
  line-height: 25px;
}

.tos .lastUpdate {
  opacity: 0.6;
  font-size: 0.9rem;
  font-style: italic;
}

.tos p {
  margin-top: 13px;
}

.tos h2 {
  font-weight: 600;
}

.tos h3 {
  opacity: 1;
  margin-top: 27px;
  font-weight: 600;
}

.tos h4 {
  margin-top: 27px;
  font-weight: 600;
}

.tos ol {
  padding-left: 27px;
  margin-top: 5px;
}

.tos strong {
  font-weight: 600;
}
/*# sourceMappingURL=index.css.map */
.privacy .privacyInner {
  margin: 40px;
  line-height: 25px;
}

.privacy .lastUpdate {
  opacity: 0.6;
  font-size: 0.9rem;
  font-style: italic;
}

.privacy p {
  margin-top: 13px;
}

.privacy h2 {
  font-weight: 600;
}

.privacy h3 {
  opacity: 1;
  margin-top: 27px;
  font-weight: 600;
}

.privacy h4 {
  margin-top: 27px;
  font-weight: 600;
}

.privacy ul {
  padding-left: 27px;
  list-style-type: circle;
  margin-top: 5px;
}

.privacy strong {
  font-weight: 600;
}
/*# sourceMappingURL=index.css.map */
.aup {
  margin: 40px;
}

.aup .aupInner {
  line-height: 25px;
}

.aup .lastUpdate {
  opacity: 0.6;
  font-size: 0.9rem;
  font-style: italic;
}

.aup p {
  margin-top: 13px;
}

.aup h2 {
  font-weight: 600;
}

.aup h3 {
  opacity: 1;
  margin-top: 27px;
  font-weight: 600;
}

.aup h4 {
  margin-top: 27px;
  font-weight: 600;
}

.aup ol {
  padding-left: 27px;
  margin-top: 5px;
}

.aup ul {
  padding-left: 27px;
  margin-top: 5px;
}

.aup strong {
  font-weight: 600;
}
/*# sourceMappingURL=index.css.map */
.header {
  background-color: white;
  z-index: 1;
  position: fixed;
  width: 100%;
  opacity: 0.95;
  height: var(--header-height);
}

.header .headerInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--page-margin);
  padding-bottom: 20px;
}

.header .headerInner img {
  height: 20px;
}

.header .headerInner .burger {
  padding-top: 5px;
  height: 17px;
  width: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.header .headerInner .burger .longLine {
  width: 20px;
  height: 3px;
  background-color: black;
}

.header .headerInner .burger .shortLine {
  width: 12px;
  height: 3px;
  background-color: black;
}

.header .headerInner .burger .topleftX {
  width: 20px;
  height: 2.5px;
  -webkit-transform: translate(0, 4.25px) rotate(40deg);
          transform: translate(0, 4.25px) rotate(40deg);
  background-color: black;
}

.header .headerInner .burger .toprightX {
  width: 20px;
  height: 2.5px;
  -webkit-transform: translate(0, -4.25px) rotate(-40deg);
          transform: translate(0, -4.25px) rotate(-40deg);
  background-color: black;
}
/*# sourceMappingURL=index.css.map */
.footer {
  width: 100%;
}

.footer .footerInner {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: 0px 40px;
  margin-bottom: 40px;
  align-items: center;
}

.footer .footerInner .socialLogos {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  opacity: 0.6;
  width: 200px;
  cursor: pointer;
}

.footer .footerInner .socialLogos .instagramLogo {
  height: 25px;
  width: 25px;
}

.footer .footerInner .socialLogos .linkedinLogo {
  height: 23px;
  width: 23px;
}

.footer .footerInner .socialLogos .youtubeLogo {
  height: 28px;
  width: 28px;
}

.footer .footerInner .policies {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  font-size: 16px;
  padding-bottom: 15px;
  text-align: center;
  opacity: 1;
}

.footer .footerInner .policies p {
  color: black;
}

.footer .footerInner .copyright h3 {
  text-align: center;
  font-size: 15px;
  opacity: 0.3;
}

@media screen and (min-width: 961px) {
  .footer .footerInner .policies {
    display: flex;
  }
  .footer .footerInner .policies p {
    margin: 0 30px;
  }
}
/*# sourceMappingURL=index.css.map */
.mobileNav {
  background-color: white;
  opacity: 0.95;
  z-index: 1;
  height: calc(100vh - var(--headerHeight));
  top: 80px;
  display: flex;
  position: fixed;
  width: 100%;
}

.mobileNav .mobileNavInner {
  width: 100%;
  margin: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobileNav .mobileNavInner .menuTitles {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
}

.mobileNav .mobileNavInner .menuTitles p {
  color: black;
  text-align: end;
  opacity: 0.5;
  font-size: 2.5rem;
  line-height: 4.25rem;
  font-weight: 600;
}

.mobileNav .mobileNavInner .menuTitles .activePath {
  opacity: 1;
}

.mobileNav .mobileNavInner .socialLogos {
  display: flex;
  justify-content: space-between;
  opacity: 0.7;
}

.mobileNav .mobileNavInner .socialLogos .instagramLogo {
  height: 25px;
  width: 25px;
}

.mobileNav .mobileNavInner .socialLogos .linkedinLogo {
  height: 23px;
  width: 23px;
}

.mobileNav .mobileNavInner .socialLogos .youtubeLogo {
  height: 28px;
  width: 28px;
}
/*# sourceMappingURL=index.css.map */
